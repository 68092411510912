<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="row" v-if="notice && notice.recordId">
        <div class="col-xl-12">
          <div class="card card-custom gutter-b card-stretch">
            <!--begin::Header-->
            <div class="card-header" v-if="notice.title">
              <div class="card-title">
                <div class="card-label">
                  <div class="font-weight-bolder">【{{notice.title}}】</div>
                </div>
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body d-flex flex-column p-0">
              <!--begin::Items-->
              <div class="flex-grow-1 card-spacer">
                <!--begin::Item-->
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="d-flex align-items-center mr-2">
                      <div v-html="notice.content"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 text-right">
                    {{notice.status}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5">
          <div>
            <auditWork></auditWork>
          </div>
          <div>
            <taskWork></taskWork>
          </div>
          <div>
            <reportWork></reportWork>
          </div>
        </div>
        <div class="col-xl-7">
          <dayThingWork></dayThingWork>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import auditWork from "@/view/pages/work/auditWork.vue";
import reportWork from "@/view/pages/work/reportWork.vue";
import taskWork from "@/view/pages/work/taskWork.vue";
import dayThingWork from "@/view/pages/work/dayThingWork.vue";
import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';
export default {
  name: "dashboard",
  components: {
    auditWork,
    reportWork,
    taskWork,
    dayThingWork
  },
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;}
    },
    work: {
      get () {
        return this.$store.state.workStore.work;
      }
    },
  },
  data() {
    return {
      notice:{},
      index: 0,
    }
  },
  watch:{
    user: function () {
      this.initData();
    },
    work: function () {
      if (this.work && this.work.noticeList && this.work.noticeList.length > 0) {
        this.notice = this.work.noticeList[this.index];
      }
    },
  },
  mounted() {
    this.initData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    initData: function () {
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      if (this.user && this.user.employeeId) {
        const work = {};
        work.empId = this.user.employeeId;
        this.$store.dispatch('workStore/getWorkList', work);
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function () { _this.initData() }, 500);
      }
      //弹窗结束
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
  }
};
</script>
