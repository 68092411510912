<template>
  <!--begin::Tiles Widget 8-->
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header">
      <div class="card-title">
        <div class="card-label">
          <div class="font-weight-bolder">任务统计</div>
          <div class="font-size-sm text-muted">相关任务{{taskWorkNum}}条,待完成{{work.kybTaskList && work.kybTaskList.length > 0 ? work.kybTaskList.length : 0}}条</div>
        </div>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-sm btn-outline-primary" v-on:click="skip(4,null)">更多</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Items-->
      <div class="flex-grow-1 card-spacer">
        <!--begin::Item-->
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center mr-2">
            <div v-if="taskWorkObj">
              <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">{{taskWorkObj.content}}</a>
              <div class="font-size-sm text-muted font-weight-bold">
                {{taskWorkObj.requireStatusStr}} {{taskWorkObj.taskTypeStr}} {{taskWorkObj.volumeRatio}}% {{taskWorkObj.name}}
              </div>
            </div>
          </div>
<!--          <div class="label label-primary label-inline font-weight-bold py-4 px-3 font-size-base">-->
<!--            查看-->
<!--          </div>-->
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center mr-2">
            <div>
              <div class="font-size-sm text-muted font-weight-bold">
                未加载{{work.kybTaskList && work.kybTaskList.length > 0 ? work.kybTaskList.length : 0}}条..........
              </div>
            </div>
          </div>
        </div>
        <!--end::Item-->
      </div>
      <!--end::Items-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Tiles Widget 8-->
</template>
<script>
import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';
export default {
  name: "auditWork",
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;
      }
    },
    work: {
      get () {
        return this.$store.state.workStore.work;
      }
    },
  },
  data() {
    return {
      notice:{},
      index: 0,
      taskWorkObj:{},
      taskWorkNum:0,
    }
  },
  watch:{
    user: function () {
      this.initData();
    },
    work: function () {
      this.taskWorkObj = this.work.kybTaskList[0];
    },
  },
  created() {
    this.initData();
    this.getTaskWorkNum();
  },
  methods: {
    initData: function () {
      if (this.user && this.user.employeeId) {
        const work = {};
        work.empId = this.user.employeeId;
        this.$store.dispatch('workStore/getWorkList', work);
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function () { _this.initData() }, 500);
      }
    },
    skip: function (num, item) {
      window.localStorage.removeItem('recordId');
      if (item && item.recordId) {
        window.localStorage.setItem('recordId', item.recordId);
      }
      switch (num) {
          // 任务
        case 4:
          this.$router.push('/task');
          break;
      }
    },
    getTaskWorkNum: function () {
      const work = {};
      work.employeeId = this.user.employeeId;
      this.$axios.fetchPost('kybTask/getTaskWorkNum', work).then((data) => {
        if (data && data.data) {
          this.taskWorkNum = data.data;
        }
      }).catch(err => {
            console.log(err);
          }
      )
    },
  }
};
</script>
