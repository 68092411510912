<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header pt-5">
        <div class="card-title">
          <div class="card-label" v-if="user && user.employeeId">
            <div class="font-weight-bolder">日程管理</div>
            <div class="font-size-sm text-muted mt-2">
              <span class="badge badge-success">参与</span>&nbsp;
              <span class="badge badge-primary">创建</span>&nbsp;
              <span class="badge badge-info">创建并参与</span>
            </div>
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body d-flex flex-column p-0">
        <!--begin::Items-->
        <div class="flex-grow-1 card-spacer" v-if="user && user.employeeId">
          <!--begin::Item-->
          <div class="d-flex align-items-center justify-content-between mb-10">
            <div class="d-flex align-items-center">
              <FullCalendar
                  locale="zh-cn"
                  ref="fullCalendar"
                  defaultView="dayGridMonth"
                  :buttonText="buttonText"
                  :header="{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
              }"
                  :plugins="calendarPlugins"
                  :weekends="calendarWeekends"
                  :events="calendarEvents"
                  @dateClick="dateClick" @eventClick="eventClick"
              />
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--end::Items-->
      </div>
      <!--end::Body-->
    </div>
    <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-hidden="true" ref="editDayThing" data-backdrop="static" data-keyboard="false" style="overflow: auto">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-sm">
                <h5>日程安排</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 text-right">
                <button class="btn btn-sm btn-primary" data-dismiss="modal">关闭</button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12 pb-3">
                <span class="text-danger">*</span>标题：
                <input v-model="dayThing.title" class="form-control" :disabled="!editStatus">
              </div>
            </div>
            <div class="row pt-3 pb-3">
              <div class="col-xl-6">
                <span class="text-danger">*</span>开始时间：
                <input class="form-control" id="startTime" :disabled="!editStatus">
              </div>
              <div class="col-xl-6">
                <span class="text-danger">*</span>结束时间：
                <input class="form-control" id="endTime" :disabled="!editStatus">
              </div>
            </div>
            <div class="row pt-3 pb-3">
              <div class="col-xl-12">
                <span class="text-danger">*</span>地点：
                <input class="form-control" v-model="dayThing.address" :disabled="!editStatus">
              </div>
            </div>
            <div class="row pt-3 pb-3">
              <div class="col-xl-12">
                <span class="text-danger">*</span>内容：
                <textarea class="form-control" v-model="dayThing.content" :disabled="!editStatus"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 pt-3 pb-3">
                <span class="text-danger">*</span>参与人员：
                <select v-model="sharesId" class="form-control selectpicker" data-max-options="6"
                        multiple data-none-selected-text="参与人员" data-live-search="true" id="shareEmp" data-size="6" :disabled="!editStatus"> </select>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 pt-3 pb-3">
                定时微信提醒参与人：
                <select class="form-control" v-model="dayThing.shareTimeWx" :disabled="!editStatus">
                  <option value="1">1天前</option>
                  <option value="2">2天前</option>
                  <option value="3">3天前</option>
                </select>
              </div>
              <div class="col-xl-6 pt-3 pb-3">
                定时微信提醒发布人：
                <select class="form-control" v-model="dayThing.createTimeWx" :disabled="!editStatus">
                  <option value="1">1天前</option>
                  <option value="2">2天前</option>
                  <option value="3">3天前</option>
                </select>
              </div>
            </div>
            <fileInput status="kybDayThing" :editStatus="editStatus" :fileList="fileList" @setFileList="setFileList"></fileInput>
            <comment status="kybDayThing" :sourceData="dayThing" :user="user"></comment>
            <div class="row pt-5" v-if="editStatus === true">
              <div class="col-sm text-center">
                <button class="btn btn-danger" v-on:click="delDayThing" v-if="dayThing.recordId && dayThing.recordId != ''">删除</button>&nbsp;&nbsp;
                <button class="btn btn-primary" v-on:click="saveDayThing">保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="delDayThing">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="dayThing && dayThing.createdBy">
              您确定要删除发布人：{{dayThing.createdBy.userName}}会议内容：{{dayThing.content}}的日程安排？
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm text-center">
                <button class="btn btn-sm btn-primary" data-dismiss="modal">取消</button>
              </div>
              <div class="col-sm text-center">
                <button class="btn btn-sm btn-primary" v-on:click="deleteDayThing">确定删除</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-hidden="true" id="previewId">
      <div class="modal-dialog modal-xl">
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12 text-center">
              <div class="row">
                <div class="col-xl-12">
                  <img id="preview" src="" class="w-100">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listMonth from "@fullcalendar/list";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import $ from 'jquery';
import {Modal} from "bootstrap";
import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import fileInput from "@/view/pages/common/fileInput.vue";
import comment from "@/view/pages/common/comment.vue";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
export default {
  components: {
    FullCalendar,
    fileInput,
    comment
  },
  computed: {
    oaEmpList: {
      get() {
        return this.$store.state.systemData.oaEmpList;
      }
    },
    dayThingList: {
      get() {
        return this.$store.state.dayThingStore.dayThingList;
      }
    },
    handEmpList: {
      get() {
        return this.$store.state.dayThingStore.handEmpList;
      }
    },
    user: {
      get () {return this.$store.getters.currentUser;}
    },
  },
  data() {
    return {
      calendarPlugins: [
        // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin, // needed for dateClick
        listMonth
      ],
      calendarWeekends: true,
      calendarEvents: [],
      buttonText: {
        today: "今天",
        month: "月",
        week: "周",
        day: "天",
        listMonth: "日程"
      },
      editStatus: true,
      dayThing: {},
      sharesId: [],
      search: {}, // 条件对象
      fileList: [],
      handOverId: '',
      notice: {},
      index: 0,
      temp: 0
    };
  },
  watch: {
    user: function () {
      this.initDayThing();
    },
    oaEmpList: function () {
      if (this.oaEmpList && this.oaEmpList.length > 0) {
        if ($('#partIn').is(':visible')) {
          this.initSelect();
        } else {
          if (this.temp > 50) {
            this.temp = 0;
          }
          this.temp++;
          // 递归 等待dom渲染完毕
          const _this = this;
          setTimeout(function () { _this.initSelect() }, 500);
        }
      }
    },
    dayThingList: function () {
      this.calendarEvents = [];
      let id = '';
      let title = '';
      let start = '';
      let end = '';
      let color = '';
      for (let i = 0; i < this.dayThingList.length; i++) {
        id = this.dayThingList[i].recordId;
        title = this.dayThingList[i].title;
        start = this.dayThingList[i].startTime;
        end = this.dayThingList[i].endTime;
        if (!(this.dayThingList[i].createdBy && this.dayThingList[i].createdBy.recordId === this.user.employeeId)) {
          let str = this.dayThingList[i].shareId.split(',');
          for (let j = 0; j < str.length; j++) {
            if (str[j] === this.user.employeeId) {
              color = '#66CDAA';
              break;
            }
          }
        } else {
          color = '#6495ED';
          if (this.dayThingList[i].shareId) {
            let str = this.dayThingList[i].shareId.split(',')
            for (let j = 0; j < str.length; j++) {
              if (str[j] === this.user.employeeId) {
                color = '#8a2be2';
                break;
              }
            }
          }
        }
        if (color) {
          this.calendarEvents.push({
            id: id,
            title: title,
            start: start,
            end: end,
            color: color,
            textColor: '#333'
          })
        }
      }
    }
  },
  created () {
    this.initDayThing();
  },
  mounted() {
    this.editDayThingT = new Modal(this.$refs.editDayThing);
    this.delDayThingT = new Modal(this.$refs.delDayThing);
  },
  methods: {
    setFileList: function (data) {
      this.fileList = data;
    },
    eventClick (info) {
      const day = {};
      day.recordId = info.event.id;
      this.viewDayThing(day);
    },
    dateClick (arg) {
      this.editDayThing(arg);
    },
    editDayThing: function (item) {
      this.editStatus = true;
      this.getDayThing(item);
    },
    viewDayThing: function (item) {
      this.editStatus = false;
      this.getDayThing(item);
    },
    getDayThing: function (item) {
      $('#file').val('');
      this.initDate();
      const seperator1 = '-';
      let date = new Date();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate;
      $('#startTime').val(currentdate);
      $('#endTime').val(currentdate);
      if (item && item.recordId) {
        this.$axios.fetchPost('kybDayThing/getDayThing', item).then((data) => {
          if (data && data.data) {
            this.dayThing = data.data;
            this.showGroupEmp(this.dayThing);
            $('#startTime').val(this.dayThing.showStartTime);
            $('#endTime').val(this.dayThing.showEndTime);
            if (data.data.attachList) {
              this.fileList = data.data.attachList;
            }
            this.editDayThingT.show();
            const depart = {};
            if (this.user.groupId) {
              const str = this.user.groupId.split(',');
              depart.recordId = str[0];
            }
            depart.workStatus = 1;
            this.$store.dispatch('systemData/loadOaEmpList', depart)
            if (data.data.createdBy && data.data.createdBy.recordId && data.data.createdBy.recordId === this.user.employeeId) {
              if (this.handOverId) {
                if (data.data.createdBy.recordId === this.handOverId) {
                  this.editStatus = true;
                }
              } else {
                if (data.data.createdBy.recordId === this.user.employeeId) {
                  this.editStatus = true;
                }
              }
            }
          }
        }).catch(err => {
              console.log(err);
            }
        )
      } else {
        this.sharesId = [];
        this.dayThing = {};
        this.fileList = [];
        const depart = {};
        if (item && item.date) {
          $('#startTime').val(item.dateStr);
          $('#endTime').val(item.dateStr);
        }
        if (this.user.groupId) {
          const str = this.user.groupId.split(',');
          depart.recordId = str[0];
        }
        depart.workStatus = 1;
        this.$store.dispatch('systemData/loadOaEmpList', depart);
        this.editDayThingT.show();
      }
    },
    initDate: function () {
      if ($('#startTime').is(':visible')) {
        $('#startTime').daterangepicker({
          'singleDatePicker': true,
          'autoUpdateInput': true,
          'showDropdowns': true,
          'timePicker': true,
          'timePicker24Hour': true,
          // 'startDate': new Date(), // 设置开始日期
          'opens': 'center',
          'drops': 'down',
          'locale': {
            'format': 'YYYY-MM-DD HH:mm:ss',
            'separator': ' - ',
            'applyLabel': '确定',
            'cancelLabel': '取消',
            'fromLabel': 'From',
            'toLabel': '到',
            'customRangeLabel': 'Custom',
            'weekLabel': 'W',
            'daysOfWeek': [
              '日',
              '一',
              '二',
              '三',
              '四',
              '五',
              '六'
            ],
            'monthNames': [
              '一月',
              '二月',
              '三月',
              '四月',
              '五月',
              '六月',
              '七月',
              '八月',
              '九月',
              '十月',
              '十一月',
              '十二月'
            ],
            'firstDay': 1
          }
        }, function () {
        })
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function () { _this.initDate() }, 500);
      }
      if ($('#endTime').is(':visible')) {
        $('#endTime').daterangepicker({
          'singleDatePicker': true,
          'autoUpdateInput': true,
          'showDropdowns': true,
          'timePicker': true,
          'timePicker24Hour': true,
          // 'startDate': new Date(), // 设置开始日期
          'opens': 'center',
          'drops': 'down',
          'locale': {
            'format': 'YYYY-MM-DD HH:mm:ss',
            'separator': ' - ',
            'applyLabel': '确定',
            'cancelLabel': '取消',
            'fromLabel': 'From',
            'toLabel': '到',
            'customRangeLabel': 'Custom',
            'weekLabel': 'W',
            'daysOfWeek': [
              '日',
              '一',
              '二',
              '三',
              '四',
              '五',
              '六'
            ],
            'monthNames': [
              '一月',
              '二月',
              '三月',
              '四月',
              '五月',
              '六月',
              '七月',
              '八月',
              '九月',
              '十月',
              '十一月',
              '十二月'
            ],
            'firstDay': 1
          }
        }, function () {
        });
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function () { _this.initDate() }, 500);
      }
    },
    initSelect: function () {
      let option = '';
      option += "<option value=''>" + '请选择' + '</option>';
      this.oaEmpList.forEach(el => {
        option += "<option value='" + el.recordId + "'>" + el.name + '</option>';
      })
      $('#shareEmp').empty();
      $('#shareEmp').append(option);
      $('#shareEmp').selectpicker('val', this.sharesId);
      $('#shareEmp').selectpicker('render');
      $('#shareEmp').selectpicker('refresh');
      $('#shareEmp').selectpicker();
    },
    initDayThing: function () {
      if (this.user && this.user.employeeId) {
        this.search = {employee: {}};
        if (this.handOverId) {
          this.search.employee.recordId = this.handOverId;
        } else {
          this.search.employee.recordId = this.user.employeeId;
        }
        this.$store.dispatch('dayThingStore/getDayThingList', this.search);
      }
    },
    delDayThing: function () {
      this.delDayThingT.show();
    },
    deleteDayThing: function () {
      this.$axios.fetchPost('kybDayThing/delDayThing', this.dayThing).then((data) => {
        if (data && data.data) {
          if (data.data === 'success') {
            alert('删除成功');
          }
          this.delDayThingT.hide();
          this.editDayThingT.hide();
          this.searchDayThing();
        }
      }).catch(err => {
            console.log(err);
          }
      )
    },
    saveDayThing: function () {
      this.dayThing.employeeId = this.user.recordId;
      this.user.recordId = this.user.employeeId;
      this.dayThing.createdBy = this.user;
      this.dayThing.departId = this.user.departId;
      this.dayThing.groupId = this.user.departId;
      this.dayThing.startTime = new Date($('#startTime').val());
      this.dayThing.endTime = new Date($('#endTime').val());
      if (!this.dayThing.title) {
        alert('请填写标题');
        return;
      }
      if (!this.dayThing.startTime) {
        alert('请选择开始时间');
        return;
      }
      if (!this.dayThing.endTime) {
        alert('请选择结束时间');
        return;
      }
      if (this.dayThing.endTime <= this.dayThing.startTime) {
        alert('结束时间要大于开始时间');
        return;
      }
      if (!this.dayThing.address) {
        alert('请填写地点');
        return;
      }
      if (!this.dayThing.content) {
        alert('请填写会议内容');
        return;
      }
      if (!this.sharesId || this.sharesId.length === 0) {
        alert('请选择参与人员');
        return;
      }
      this.dayThing.shareId = null;
      for (let obj of this.sharesId) {
        if (this.dayThing.shareId) {
          this.dayThing.shareId = this.dayThing.shareId + ',' + obj;
        } else {
          this.dayThing.shareId = obj;
        }
      }
      // 通过form表单进行提交
      const param = new FormData();
      param.append('dayThing', JSON.stringify(this.dayThing));
      if (this.fileList && this.fileList.length > 0) {
        for (let i = 0; i < this.fileList.length; i++) {
          if (!this.fileList[i].downloadUrl) {
            param.append('files', this.fileList[i]);
          }
        }
      }
      //弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$axios.formPost('kybDayThing/addDayThing', param).then((data) => {
        if (data.data === 'success') {
          alert('提交成功');
          this.editDayThingT.hide();
          this.initDayThing();
        } else if (data.data === 'false') {
          alert('文件上传失败');
          this.editDayThingT.hide();
          this.initDayThing();
        } else {
          alert('系统错误');
        }
        //弹窗结束
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }).catch(err => {
            console.log(err);
          }
      )
    },
    searchDayThing: function () {
      this.search.recordId = null;
      this.search.employee = {};
      if (this.handOverId) {
        this.search.employee.recordId = this.handOverId;
      } else {
        this.search.employee.recordId = this.user.employeeId;
      }
      this.$store.dispatch('dayThingStore/getDayThingList', this.search);
    },
    showGroupEmp: function (item) {
      this.sharesId = [];
      const shardStr = item.shareId.split(',');
      if (shardStr && shardStr.length > 0) {
        for (let obj of shardStr) {
          this.sharesId.push(obj);
        }
      }
    },
  }
};
</script>
